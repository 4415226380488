<template>
  <b-modal
    id="modal-article-create"
    ref="modal-article-create"
    ok-only
    size="lg"
    ok-variant="primary"
    :ok-title="$t('confirm')"
    modal-class="modal-primary"
    hide-footer
    centered
    :title="$t('actions.create-article')"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
    <b-col md="12">
      <button
        class="d-none"
        type="submit"
      />
      <b-tabs pills>
        <b-tab
          :title="$t('labels.create-article.default-article')"
          active
          @click="isDefaultArticle = true"
        >
          <validation-observer
            ref="createDefaultArticleForm"
            v-slot="{ invalid }"
          >
            <b-col class="text-right pt-1">
              <b-form-checkbox
                v-model="article.is_featured"
                name="featured-article"
                inline
                switch
                size="16"
              >
                <span style="font-size:14px; color:#6e6b7b;"
                      class="d-flex"
                > {{ $t('labels.create-article.featured') }}
                  <feather-icon
                    v-b-tooltip="$t('article-feature-description')"
                    icon="InfoIcon"
                    size="16"
                    style="margin-left: 6px; margin-top: 6px;"
                  />
                </span>
              </b-form-checkbox>
            </b-col>
            <!-- Title -->
            <b-form-group
              :label="$t('labels.create-article.title')"
              label-for="title"
            >
              <validation-provider
                v-slot="{ errors }"
                name="title"
                rules="required|min:4"
                vid="title"
              >
                <b-form-input
                  id="title"
                  v-model="article.title"
                  name="title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-article.title-placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Title -->

            <!-- Description -->
            <b-form-group
              :label="$t('labels.create-article.description')"
              label-for="description-area"
            >
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required|min:4"
                vid="description"
              >
                <b-form-textarea
                  id="description-area"
                  v-model="article.description"
                  rows="6"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-article.description-placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--/ Description -->

            <!-- Article Category -->
            <b-form-group
              :label="$t('labels.create-article.category')"
              label-for="articleCategory"
            >
              <validation-provider
                v-slot="{ errors }"
                name="articleCategory"
                rules="required"
                vid="articleCategory"
              >
                <b-form-select
                  id="articleCategory"
                  v-model="article.articleCategory"
                  :options="articleCategoryOptions"
                  name="access-type"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Article Category -->

            <!-- Video -->
            <b-form-group
              :label="$t('labels.create-article.video')"
              label-for="articleCategory"
            >
              <validation-provider
                v-slot="{ errors }"
                name="video"
                vid="video"
              >
                <b-form-input
                  id="video"
                  v-model="article.video"
                  name="video"
                  type="url"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-article.video-placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Video -->

            <!-- Image -->
            <b-form-group
              :label="$t('labels.create-article.image')"
              label-for="article-image"
            >
              <validation-provider
                v-slot="{ errors }"
                name="image"
                rules="image|size:800"
                :custom-messages="{ image: $t('messages.invalid-image') }"
                vid="image"
              >
                <b-form-file
                  id="article-image"
                  ref="articleImage"
                  v-model="articleImage"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-article.image-placeholder')"
                  :browse-text="$t('browseButton')"
                  accept="image/jpeg, image/png, image/gif"
                />
                <small class="text-danger">{{ errors[0] }}</small><br>
                <small>{{ $t('labels.create-article.image-accept') }}</small>
                <b-img
                  v-if="article.image"
                  rounded
                  fluid
                  :src="article.image"
                  height="150"
                  width="150"
                  class="mt-2"
                />
                <b-button
                  v-if="article.image"
                  variant="outline-danger"
                  class="btn-icon px-1 ml-2"
                  @click="() => { article.image = null; article.file = null; $refs.articleImage.reset() }"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </validation-provider>
            </b-form-group>
            <!--/ Image -->

            <!-- Theme -->
            <b-form-group
              :label="$t('labels.create-article.theme')"
              label-for="theme"
              class="mt-2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="theme"
                vid="theme"
              >
                <select
                  id="theme"
                  v-model="article.theme"
                  :options="themes"
                  name="theme"
                  class="custom-select theme-selector"
                >
                  <option
                    v-for="t in themes"
                    :key="t.text"
                    :value="t.value"
                    :class="'theme-' + t.value"
                  >
                    {{ t.text }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <theme-preview :theme="article.theme" />
            <!-- /Theme -->
            <b-row class="mt-2">
              <b-col
                md="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="isProcessing || invalid"
                  @click="createArticle('createDefaultArticleForm')"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t("actions.create-article") }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-tab>
        <b-tab
          :title="$t('labels.create-article.custom-article')"
          @click="isDefaultArticle = false"
        >
          <validation-observer
            ref="createCustomArticleForm"
            v-slot="{ invalid }"
          >
            <b-col class="text-right pt-1">
              <b-form-checkbox
                v-model="article.is_featured"
                name="featured-article"
                inline
                switch
                size="16"
              >
                <span style="font-size:14px; color:#6e6b7b;"
                      class="d-flex"
                >
                  {{ $t('actions.featured') }}
                  <feather-icon
                    v-b-tooltip="$t('article-feature-description')"
                    icon="InfoIcon"
                    size="16"
                    style="margin-left: 6px; margin-top: 6px;"
                  />
                </span>
              </b-form-checkbox>
            </b-col>
            <!-- Title -->
            <b-form-group
              :label="$t('labels.create-article.title')"
              label-for="title"
            >
              <validation-provider
                v-slot="{ errors }"
                name="title"
                rules="required|min:4"
                vid="title"
              >
                <b-form-input
                  id="title"
                  v-model="article.title"
                  name="title"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('labels.create-article.title-placeholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- / Title -->

            <!-- Article Category -->
            <b-form-group
              :label="$t('labels.create-article.category')"
              label-for="articleCategory"
            >
              <validation-provider
                v-slot="{ errors }"
                name="articleCategory"
                rules="required"
                vid="articleCategory"
              >
                <b-form-select
                  id="articleCategory"
                  v-model="article.articleCategory"
                  :options="articleCategoryOptions"
                  name="access-type"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- / Article Category -->

            <!-- Theme -->
            <b-form-group
              :label="$t('labels.create-problem.theme')"
              label-for="theme"
              class="mt-2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="theme"
                vid="theme"
              >
                <select
                  id="theme"
                  v-model="article.theme"
                  :options="themes"
                  name="theme"
                  class="custom-select theme-selector"
                >
                  <option
                    v-for="t in themes"
                    :key="t.text"
                    :value="t.value"
                    :class="'theme-' + t.value"
                  >
                    {{ t.text }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <theme-preview :theme="article.theme" />
            <!-- /Theme -->

            <!-- create edit template -->
            <b-form-group>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                class="mt-2"
                @click="showTemplate = true"
              >
                {{ templateData ? $t('labels.create-article.update-template') : $t('labels.create-article.create-template') }}
              </b-button>
              <create-template
                :show="showTemplate"
                :template="templateData"
                :title="$t('labels.create-article.template')"
                :initial-template-options="[
                  { value: 'carousel', text: 'Carousel' },
                  { value: 'image-paragraph', text: 'Image & Paragraph' },
                ]"
                @close="showTemplate = false"
                @templateStored="updateTemplate"
              />
              <validation-provider
                v-slot="{ errors }"
                name="theme"
                rules="required"
                vid="theme"
              >
                <input
                  v-model="templateData"
                  hidden
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- create edit template -->

            <b-row class="mt-2">
              <b-col
                md="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="isProcessing || invalid"
                  @click="createArticle('createCustomArticleForm')"
                >
                  <b-spinner
                    v-show="isProcessing"
                    small
                  />
                  {{ $t("actions.create-article") }}
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BImg,
  BFormSelect,
  BTabs,
  BTab,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate/dist/vee-validate.full'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { validatorYoutubeUrlValidator } from '@/@core/utils/validations/validators'
import CreateTemplate from '@views/common/templates/CreateEdit.vue'
import ThemePreview from '@views/common/components/ThemePreview.vue'
import themes from './themes'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BButton,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BImg,
    BFormSelect,
    BTabs,
    BTab,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    CreateTemplate,
    ThemePreview,
  },
  directives: {
    Ripple,
  },
  props: {
    toggle: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    article: {
      image: null,
      description: null,
      title: null,
      articleCategory: 'academic',
      video: null,
      theme: 1,
      is_featured: false,
    },
    articleCategoryOptions: [
      { value: 'philanthropic', text: 'Philanthropic' },
      { value: 'academic', text: 'Academic' },
      { value: 'project', text: 'Project' },
    ],
    isProcessing: false,
    articleImage: null,
    showTemplate: false,
    templateData: null,
    themes,
    isDefaultArticle: true,
  }),
  watch: {
    toggle(value) {
      if (value === true) {
        this.$refs['modal-article-create'].show()
      } else {
        this.$refs['modal-article-create'].hide()
      }
    },
    articleImage() {
      /*
    Initialize a File Reader object
    */
      const reader = new FileReader()

      /*
    Add an event listener to the reader that when the file
    has been loaded, we flag the show preview as true and set the
    image to be what was read from the reader.
    */
      reader.addEventListener(
        'load',
        () => {
          this.article.image = reader.result
        },
        false,
      )

      /*
    Check to see if the file is not empty.
    */
      if (this.articleImage) {
        /*
            Ensure the file is an image file.
        */
        if (/\.(jpe?g|png|gif)$/i.test(this.articleImage.name)) {
          /*
            Fire the readAsDataURL method which will read the file in and
            upon completion fire a 'load' event which we will listen to and
            display the image in the preview.
            */
          this.article.file = this.articleImage
          reader.readAsDataURL(this.articleImage)
        }
      }
    },
  },
  methods: {
    updateTemplate(data) {
      this.templateData = data
      this.showTemplate = false
    },
    createArticle(formRef) {
      this.$refs[formRef].validate().then(success => {
        let youtubeUrl = ''
        // validate video url
        if (
          this.isDefaultArticle
          && this.article.video
          && this.article.video.length > 0
        ) {
          youtubeUrl = validatorYoutubeUrlValidator(this.article.video)
          if (!youtubeUrl) {
            this.showErrorMessage({
              response: {
                status: 422,
                statusText: 'Invalid Url',
                data: { message: 'The video url is invalid' },
              },
            })
            return
          }
        }

        if (success) {
          this.isProcessing = true

          const config = {
            headers: {
              'content-type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.accessToken}`,
            },
          }

          const data = new FormData()
          data.append('title', this.article.title)
          data.append('article_category', this.article.articleCategory)
          data.append('theme_id', this.article.theme)
          data.append('is_featured', this.article.is_featured ? 1 : 0)
          if (this.isDefaultArticle) {
            data.append('type', 'default')
            data.append('description', this.article.description)
            if (this.article.image) data.append('image', this.article.file)
            if (youtubeUrl) data.append('video', youtubeUrl)
          } else {
            data.append('type', 'custom')
            if (this.templateData) data.append('template_id', this.templateData.id)
          }
          axios
            .post('/api/v1/article/', data, config)
            .then(response => {
              this.$emit('created')
              this.showSuccessMessage(response)
              this.$emit('close')
              this.article = {
                image: null,
                description: null,
                title: null,
                articleCategory: 'academic',
                video: null,
                theme: 1,
                is_featured: false,
              }
              this.templateData = null
              this.isDefaultArticle = true
            })
            .catch(error => {
              if (error.response?.data?.errors) this.$refs[formRef].setErrors(error.response.data.errors)
              else this.showErrorMessage(error)
            })
            .finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
